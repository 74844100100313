// PrivacyPolicy.js
import React, { useEffect, useState } from "react";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import SubmittingLoader from "../Shared/SubmittingLoader/SubmittingLoader";

const Job = () => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  useEffect(() => {
    const url = "https://t.ly/VtvIh"; // URL to redirect to
    window.open(url, "_blank"); // Open the URL in a new tab
  }, []);

  const handleSubmittingForm = async () => {
    setIsSubmittingForm(true);
    const url = "https://t.ly/VtvIh"; // URL to redirect to
    window.open(url, "_blank"); // Open the URL in a new tab
    setIsSubmittingForm(false);
  };

  return (
    <ErrorBoundary>
      <div className="bg2 p42">
        <div className="container_12">
          <div className="sub_form">
            <div className="grid_5">
              <i className="fa fa-envelope-o"></i>
            </div>
            <br />
            <br />
            <br />
            <br />
            <form id="subscribe-form" className="clearfix">
              <div className="grid_2">
                <a
                  onClick={handleSubmittingForm}
                  data-type="submit"
                  className="link"
                >
                  Apply for Job Now?
                </a>
              </div>
              {isSubmittingForm && <SubmittingLoader />}
            </form>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Job;
