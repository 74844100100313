import "swiper/swiper.min.css";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import icon1 from "../../Resources/page1_ico1.png";
import icon2 from "../../Resources/page1_ico2.png";
import icon3 from "../../Resources/page1_ico3.png";
import vote from "../../Resources/oap.jpg";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { Modal } from "react-st-modal";
import axios from "axios";
import { formatStringToDate, serverLink } from "../../Resources/Url";
import LoginLoader from "../Shared/LoginLoader/LoginLoader";

const Home = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [userIPAddress, setUserIPAddress] = useState("");
  const [currentProgram, setCurrentProgram] = useState(null);
  const [OAP, setOAP] = useState([]);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: months are zero-indexed
  const currentDay = currentDate.getDate();

  function formatDateToDDMMYYYY(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so we add 1
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const [oap] = useState(
    typeof props?.HomePageData.oap !== "undefined"
      ? arrayShuffle(props?.HomePageData.oap)
      : []
  );

  const [popupNotification] = useState(
    typeof props?.HomePageData.popupNotification !== "undefined"
      ? arrayShuffle(props?.HomePageData.popupNotification)
      : []
  );

  const [programs] = useState(
    typeof props?.HomePageData.programs !== "undefined"
      ? arrayShuffle(props?.HomePageData.programs)
      : []
  );

  const [news] = useState(
    typeof props.HomePageData.news !== "undefined"
      ? props.HomePageData.news
      : []
  );

  const filteredNews = news
    .filter((x) => x.StationId === Number(1) || x.StationId === Number(0))
    .slice(0, 6);

  const [podcast] = useState(
    typeof props.HomePageData.podcast !== "undefined"
      ? props.HomePageData.podcast
      : []
  );

  const filteredPodcast = podcast
    .filter((x) => x.StationId === Number(1) || x.StationId === Number(0))
    .slice(0, 6);

  const [pageReloaded, setPageReloaded] = useState(false);

  const [
    isSubmitPhoneNumberAndIppAddress,
    setIsSubmitPhoneNumberAndIppAddress,
  ] = useState(false);

  const haveOpted = JSON.parse(localStorage.getItem("haveOpted")) || [];

  const filteredOAPs = oap.filter((x) => x.StationId === Number(1));
  const filteredPopupNotification = popupNotification.filter(
    (x) => x.StationId === Number(1)
  );

  const filteredPrograms = programs
    .filter((x) => x.StationId === Number(1))
    .slice(0, 6);

  const updateNumberOfHits = () => {
    const sendData = {
      IpAddress: userIPAddress,
      StationId: 1,
      Date: `${currentMonth}/${currentDay}/${currentYear}`,
    };

    // Call the endpoint to insert the processed data into the table
    axios
      .post(`${serverLink}shared/hit`, sendData)
      .then((response) => {
        console.log("Data inserted successfully", response);
      })
      .catch((error) => {
        console.error("Error inserting data: ", error);
      });
  };

  useEffect(() => {
    if (!pageReloaded && haveOpted === 0) {
      setPageReloaded(true);
      window.location.reload();
    }
    if (haveOpted && Object.keys(haveOpted).length > 0) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [pageReloaded]);

  useEffect(() => {
    if (Object.keys(filteredPrograms).length > 0) {
      const checkProgramMatch = () => {
        const currentTime = new Date();

        const matchingProgram = programs.find((program) => {
          const startTime = new Date();
          const [startHour, startMinute] = program.StartTime.split(":");
          startTime.setHours(startHour, startMinute, 0, 0);

          const endTime = new Date();
          const [endHour, endMinute] = program.EndTime.split(":");
          endTime.setHours(endHour, endMinute, 0, 0);

          return currentTime >= startTime && currentTime <= endTime;
        });

        if (matchingProgram) {
          setCurrentProgram(matchingProgram);
        }
      };

      checkProgramMatch(); // Initial check on component mount

      const interval = setInterval(checkProgramMatch, 60000); // Check every minute

      return () => {
        clearInterval(interval);
      };
    }
    if (Object.keys(filteredPrograms).length < 1) {
      const matchingProgram = filteredPrograms.find(
        (r) => r.ProgramId === Number(1)
      );

      setCurrentProgram(matchingProgram);
    }
  }, []);

  const hasUpdateNumberOfHitsRun = useRef(false);

  function closeModalAfterGottenIP() {
    setIsOpen(false);
  }

  const onCancel = async () => {
    setIsSubmitPhoneNumberAndIppAddress(true);
    const newItem = {
      IpAddress: userIPAddress,
    };

    setTimeout(async () => {
      localStorage.setItem("haveOpted", JSON.stringify(newItem));

      setTimeout(async () => {
        setIsSubmitPhoneNumberAndIppAddress(false);
        closeModalAfterGottenIP();
        window.location.reload();
      }, 2000);
    }, 1000);
  };

  function closeVotingSystem() {
    setIsSubmitPhoneNumberAndIppAddress(true);
    const newItem = {
      IpAddress: userIPAddress,
    };

    setTimeout(async () => {
      localStorage.setItem("haveOpted", JSON.stringify(newItem));

      setTimeout(async () => {
        setIsSubmitPhoneNumberAndIppAddress(false);
        closeModalAfterGottenIP();
        window.location.reload();
      }, 2000);
    }, 1000);
  }

  useEffect(() => {
    const fetchUserIPAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        const { ip } = response.data;
        setUserIPAddress(ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchUserIPAddress().then((r) => {});
  }, []);

  const handleVoteNow = async (e) => {
    localStorage.removeItem("haveOpted");
    setIsSubmitPhoneNumberAndIppAddress(true);
    const newItem = {
      IpAddress: userIPAddress,
      StationId: 1,
    };

    setTimeout(async () => {
      localStorage.setItem("haveOpted", JSON.stringify(newItem));

      setTimeout(async () => {
        setIsSubmitPhoneNumberAndIppAddress(false);
        closeModalAfterGottenIP();
        window.location.href = "https://www.vote.enyinba.com";
      }, 2000);
    }, 1000);
  };

  useEffect(() => {
    setTimeout(async () => {
      setOAP(filteredOAPs);
    }, 1000);
  }, [filteredOAPs, filteredPrograms]);

  const onAttemptClose = () => {
    window.alert(`Please click cancel or vote now!`);
  };

  useEffect(() => {
    if (!hasUpdateNumberOfHitsRun.current) {
      updateNumberOfHits();
      hasUpdateNumberOfHitsRun.current = true;
    }
  }, [haveOpted]);

  if (Object.keys(OAP).length === 0) {
    return <HomeContentLoader />;
  }

  return (
    <ErrorBoundary>
      <div id="content">
        <div className="container_12 p100">
          <div className="grid_4 wrap">
            <div className="thumb">
              <div className="circle">
                <img src={icon1} alt="" />
              </div>
              <h3
                style={{
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: "300",
                  textTransform: "uppercase",
                  display: "block",
                  fontSize: "35px",
                  lineHeight: "30px",
                }}
              >
                GENERAL ENTERTAINMENT
              </h3>
              <h4></h4>
              <p>
                Enyimba 94.3fm provides entertainment through music shows, local
                artist interviews, cultural programs, and more, engaging and
                entertaining their audience alongside their main focus on
                sports, politics, and current affairs.
              </p>
              <a href="#" className="link2">
                more info
              </a>
            </div>
          </div>
          <div className="grid_4 wrap">
            <div className="thumb">
              <div className="circle">
                <img src={icon2} alt="" />
              </div>
              <h3
                style={{
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: "300",
                  textTransform: "uppercase",
                  display: "block",
                  fontSize: "35px",
                  lineHeight: "30px",
                }}
              >
                CULTURE & EDUCATION
              </h3>
              <h4></h4>
              <p>
                Enyimba 94.3fm delivers timely sports, politics, and current
                affairs updates through news bulletins, analysis, interviews,
                and discussions, keeping listeners informed about Enyimba city
                and beyond. We do more than aforementioned.
              </p>
              <a href="#" className="link2">
                more info
              </a>
            </div>
          </div>
          <div className="grid_4">
            <div className="thumb">
              <div className="circle">
                <img src={icon3} alt="" />
              </div>
              <h3
                style={{
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: "300",
                  textTransform: "uppercase",
                  display: "block",
                  fontSize: "35px",
                  lineHeight: "30px",
                }}
              >
                NEWS & CURRENT AFFAIRS
              </h3>
              <h4></h4>
              <p>
                Enyimba 94.3fm highlights local culture, traditions, and
                heritage through programs, while also offering educational
                content, interviews with scholars, discussions on educational
                topics, and initiatives to promote learning and development in
                Enyimba city.
              </p>
              <a href="#" className="link2">
                more info
              </a>
            </div>
          </div>
        </div>

        {/*ADVERT START*/}
        <div
          className="bg1 p93"
          style={{
            backgroundColor: "rgba(196,190,190,0.2)",
          }}
        >
          <div className="container_12">
            <div className="grid_12">
              {isMobile ? (
                <div className="info_block">
                  <h3
                    className="col2"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {" "}
                    DO YOU WANT AN ADVERT SPACE ON <br />
                    ENYIMBA FM website? <Link to="/contact">
                      click here
                    </Link>{" "}
                    <br />
                  </h3>
                  <h4
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    The station that brings you intriguing programs
                  </h4>
                  <a href="https://live.enyinba.com" target="_blank">
                    <i className="fa fa-play-circle"></i>
                  </a>
                </div>
              ) : (
                <div className="info_block">
                  <h3 className="col2">
                    {" "}
                    DO YOU WANT AN ADVERT SPACE ON <br />
                    ENYIMBA FM website? <Link to="/contact">
                      click here
                    </Link>{" "}
                    <br />
                  </h3>
                  <h4>The station that brings you intriguing programs</h4>
                  <a href="https://live.enyinba.com" target="_blank">
                    <i className="fa fa-play-circle"></i>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*ADVERT END*/}

        {/*TOPS NEWS START*/}
        {filteredNews && Object.keys(filteredNews).length > 0 && (
          <>
            <div className="container_12 p100">
              <h3>TOP NEWS</h3>
              {filteredNews.map((item, index) => {
                return (
                  <>
                    <Link to={`/news/${item.Slug}`}>
                      <div className="grid_4 wrap">
                        <div className="thumb">
                          <img
                            src={item.Cover}
                            alt=""
                            width="300"
                            height="287.44"
                          />
                          {/*<div className="circle">*/}
                          <div
                            style={{
                              fontSize: "18px",
                              color: "white",
                              fontStyle: "italic",
                              height: "25px",
                              width: "300px",
                              alignContent: "center",
                              marginRight: "5px",
                              backgroundColor: "#b38631",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {formatStringToDate(item.CreatedDate)}
                          </div>
                          {/*</div>*/}
                          <div
                            style={{
                              fontSize: "20px",
                              color: "black",
                              TextTransform: "lowercase",
                              fontWeight: "bold",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.Title}
                          </div>

                          <div
                            style={{
                              marginTop: "10px",
                              fontSize: "17px",
                              color: "black",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.ParagraphOne.split(" ").slice(0, 5).join(" ")}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })}
            </div>
          </>
        )}
        {/*TOPS NEWS END*/}

        <div
          className="bg1 p93"
          style={{
            backgroundColor: "rgba(196,190,190,0.2)",
          }}
        >
          <div className="container_12">
            <div className="grid_12">
              <div className="info_block">
                <h3 className="col2">
                  Captivating moments on ENYIMBA FM <br />
                  <span className="col1">radio station!</span>
                </h3>
                <h4>The station that brings you intriguing programs</h4>
                <a href="https://live.enyinba.com" target="_blank">
                  <i className="fa fa-play-circle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/*PROGRAMME START*/}
        {filteredPrograms && Object.keys(filteredPrograms).length > 0 && (
          <>
            <div className="container_12 p100">
              <h3>Programmes</h3>
              {filteredPrograms.map((item, index) => {
                return (
                  <>
                    <Link to={`/program/${item.Slug}`}>
                      <div
                        className="grid_4 wrap"
                        style={{
                          margin: "5px",
                        }}
                      >
                        <div className="thumb">
                          <img
                            src={item.Cover}
                            alt=""
                            width="300"
                            height="287.44"
                          />
                          {/*<div className="circle">*/}

                          {/*</div>*/}
                          <div
                            style={{
                              color: "black",
                              TextTransform: "lowercase",
                              fontWeight: "bold",
                              fontSize: "20px",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.ProgramName.split(" ").slice(0, 5).join(" ")}
                          </div>
                          <div
                            style={{
                              color: "black",
                              textAlign: "justify", // Center-align text
                            }}
                          >
                            {/*{item.ShortDescription.split(" ")*/}
                            {/*  .slice(0, 5)*/}
                            {/*  .join(" ")}*/}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })}
            </div>
          </>
        )}
        {/*PROGRAMME ENDS*/}

        {/*PODCAST START*/}
        {filteredPodcast && Object.keys(filteredPodcast).length > 0 && (
          <>
            <div className="container_12 p100">
              <h3>Podcasts</h3>
              {filteredPodcast.map((item, index) => {
                return (
                  <>
                    <Link to={`/podcast/${item.Slug}`}>
                      <div className="grid_4 wrap">
                        <div className="thumb">
                          <img
                            src={item.Image}
                            alt=""
                            width="300"
                            height="287.44"
                          />
                          {/*<div className="circle">*/}

                          {/*</div>*/}
                          <div
                            style={{
                              color: "black",
                              TextTransform: "lowercase",
                              fontWeight: "bold",
                              fontSize: "20px",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.Title}
                          </div>
                          <div
                            style={{
                              color: "black",
                              textAlign: "justify", // Center-align text
                            }}
                          >
                            {item.ShortDescription.split(" ")
                              .slice(0, 5)
                              .join(" ")}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })}
            </div>
          </>
        )}
        {/*PODCAST ENDS*/}
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
