import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatStringToDate, serverLink } from "../../Resources/Url";
import { setLoginDetails } from "../../Actions/Actions";
import axios from "axios";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import page2_img1 from "../../Resources/page2_img1.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import icon1 from "../../Resources/page1_ico1.png";
import icon2 from "../../Resources/page1_ico2.png";
import icon3 from "../../Resources/page1_ico3.png";
import arrayShuffle from "array-shuffle";

const NewsDetails = (props) => {
  const navigate = useNavigate();
  const [news, setNews] = useState([]);
  const [oap] = useState(
    typeof props.HomePageData.oap !== "undefined" ? props.HomePageData.oap : []
  );

  const [news_] = useState(
    typeof props.HomePageData.news !== "undefined"
      ? props.HomePageData.news
      : []
  );

  const filteredNews = news_
    .filter((x) => x.Title.includes(news.Title))
    .slice(0, 6);

  const [NewsList_] = useState(
    typeof props?.HomePageData.programs !== "undefined"
      ? arrayShuffle(props?.HomePageData.programs)
      : []
  );

  const reloadPage = () => {
    window.location.reload();
  };

  const url = window.location.pathname;
  const splitUrl = url.split("-");
  const lastCharacter = splitUrl.pop();

  const sendData = {
    NewsID: Number(lastCharacter),
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.post(`${serverLink}shared/news`, sendData);

        setNews(response.data);
      } catch (error) {
        navigate("/");
        console.error("Error fetching:", error);
      }
    };

    fetchProduct().then((r) => {});
  }, [lastCharacter]);

  const newsList =
    NewsList_ &&
    NewsList_.length > 0 &&
    NewsList_.filter((x) => x.NewsID === Number(lastCharacter));

  if (news && Object.keys(news).length === 0) {
    return <HomeContentLoader />;
  }

  return (
    <ErrorBoundary>
      <div id="content">
        <div className="container_12 p100">
          <div className="grid_4 wrap">
            <>
              <div
                className="grid_12 mb46"
                style={{
                  textAlign: "justify",
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    color: "black",
                    TextTransform: "lowercase",
                    marginLeft: "10px",
                    fontWeight: "bold",
                    textAlign: "left", // Center-align text
                  }}
                >
                  {news.Title}
                </div>
                <h4
                  style={{
                    marginTop: "10px",
                    fontSize: "16px",
                    marginLeft: "10px",
                    color: "black",
                    fontWeight: "400px",
                  }}
                >
                  <a href="#" className="link1">
                    {
                      oap
                        .filter((i) => i.OAPId === Number(news.StaffId))
                        .map((x) => (
                          <Link key={x.StaffId} to={`/staff/${x.Slug}`}>
                            {x.FullName}
                          </Link>
                        ))[0]
                    }
                  </a>{" "}
                  {" - "}
                  {formatStringToDate(news.CreatedDate)}
                </h4>
                <p
                  style={{
                    fontSize: "16px",
                    marginLeft: "10px",
                    color: "black",
                  }}
                >
                  {news.ParagraphOne}
                </p>
                {news.Cover ? (
                  <img
                    src={`${news.NewImage}`}
                    alt="img"
                    style={{ width: "100%", height: "auto" }}
                    className="grid_4"
                  />
                ) : (
                  <img
                    src="https://dummyimage.com/400x300"
                    alt="img"
                    style={{ width: "100%", height: "auto" }}
                    className="grid_4"
                  />
                )}
                <p
                  style={{
                    fontSize: "16px",
                    marginLeft: "10px",
                    color: "black",
                  }}
                >
                  {news.ParagraphTwo}
                </p>
                <br />
                <p
                  style={{
                    fontSize: "16px",
                    marginLeft: "10px",
                    color: "black",
                  }}
                >
                  {news.ParagraphThree}
                </p>
              </div>
            </>
          </div>
        </div>
        {/*TOPS NEWS START*/}
        {filteredNews && Object.keys(filteredNews).length > 0 && (
          <>
            <div className="container_12">
              <h3>RELATED NEWS</h3>
              {filteredNews.map((item, index) => {
                return (
                  <div onClick={reloadPage} key={index}>
                    <Link to={`/news/${item.Slug}`}>
                      <div className="grid_4 wrap">
                        <div className="thumb">
                          <img
                            src={item.Cover}
                            alt=""
                            width="300"
                            height="287.44"
                          />
                          {/*<div className="circle">*/}
                          <div
                            style={{
                              fontSize: "18px",
                              color: "white",
                              fontStyle: "italic",
                              height: "25px",
                              width: "300px",
                              alignContent: "center",
                              marginRight: "5px",
                              backgroundColor: "#b38631",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {formatStringToDate(item.CreatedDate)}
                          </div>
                          <div
                            style={{
                              fontSize: "20px",
                              color: "black",
                              TextTransform: "lowercase",
                              fontWeight: "bold",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.Title}
                          </div>

                          <div
                            style={{
                              marginTop: "10px",
                              fontSize: "17px",
                              color: "black",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.ParagraphOne.split(" ").slice(0, 8).join(" ")}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {/*TOPS NEWS END*/}
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetails);
