import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import { Link, useNavigate } from "react-router-dom";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";

const Podcast = (props) => {
  const navigate = useNavigate();
  const [podcast_, setPodcast_] = useState([]);
  const lastCharacterMatch = window.location.href.match(/(\d+)$/);
  const lastCharacter = lastCharacterMatch
    ? Number(lastCharacterMatch[0])
    : null;

  const [podcast] = useState(
    typeof props.HomePageData.podcast !== "undefined"
      ? props.HomePageData.podcast
      : []
  );

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const filteredPodcast = podcast
          .filter((x) => x.PodcastId === lastCharacter)
          .slice(0, 1);

        setPodcast_(filteredPodcast);
      } catch (error) {
        navigate("/");
        console.error("Error fetching:", error);
      }
    };

    fetchProduct().then((r) => {});
  }, [lastCharacter]);

  if (podcast_ && Object.keys(podcast_).length === 0) {
    return <HomeContentLoader />;
  }

  const reloadPage = () => {
    window.location.reload();
  };

  const audioPlayerStyle = {
    backgroundImage: `url(${podcast_[0].Image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "white",
    padding: "20px",
    textAlign: "center",
  };

  const filteredPodcast = podcast
    .filter((x) => x.Title.includes(podcast_[0].Title))
    .slice(0, 6);

  console.log("filteredPodcast", filteredPodcast);
  // console.log("podcast_[0].Title", podcast_[0].Title);

  return (
    <ErrorBoundary>
      <div id="content">
        <div className="audio-player-container" style={audioPlayerStyle}>
          <h1>{podcast_[0].Title}</h1>

          <audio
            controls
            style={{ background: "rgba(0, 0, 0, 0.6)", borderRadius: "5px" }}
          >
            <source src={podcast_[0].Audio} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>

        <div className="container_12">
          {" "}
          <br />
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "black",
            }}
          >
            {podcast_[0].Title}
          </p>
          <p style={{ color: "black" }}>{podcast_[0].LongDescription}</p>
        </div>

        {/*PODCAST START*/}
        {filteredPodcast && Object.keys(filteredPodcast).length > 0 && (
          <>
            <div className="container_12 p100">
              <h3>Related Podcasts</h3>
              {filteredPodcast.map((item, index) => {
                return (
                  <div onClick={reloadPage} key={index}>
                    <Link to={`/podcast/${item.Slug}`}>
                      <div className="grid_4 wrap">
                        <div className="thumb">
                          <img
                            src={item.Image}
                            alt=""
                            width="300"
                            height="287.44"
                          />
                          {/*<div className="circle">*/}

                          {/*</div>*/}
                          <div
                            style={{
                              color: "black",
                              TextTransform: "lowercase",
                              fontWeight: "bold",
                              fontSize: "20px",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.Title}
                          </div>
                          <div
                            style={{
                              color: "black",
                              textAlign: "justify", // Center-align text
                            }}
                          >
                            {item.ShortDescription.split(" ")
                              .slice(0, 5)
                              .join(" ")}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {/*PODCAST ENDS*/}
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Podcast);
