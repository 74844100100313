import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatStringToDate, serverLink } from "../../Resources/Url";
import { setLoginDetails } from "../../Actions/Actions";
import icon1 from "../../Resources/page1_ico1.png";
import icon2 from "../../Resources/page1_ico2.png";
import icon3 from "../../Resources/page1_ico3.png";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { Link } from "react-router-dom";

const Schedule = (props) => {
  const [currentProgram, setCurrentProgram] = useState(null);
  const [OAP, setOAP] = useState([]);

  const [oap] = useState(
    typeof props?.HomePageData.oap !== "undefined"
      ? arrayShuffle(props?.HomePageData.oap)
      : []
  );

  const [programs] = useState(
    typeof props?.HomePageData.programs !== "undefined"
      ? arrayShuffle(props?.HomePageData.programs)
      : []
  );

  const [news] = useState(
    typeof props.HomePageData.news !== "undefined"
      ? props.HomePageData.news
      : []
  );

  const [newsImage] = useState(
    typeof props.HomePageData.newsImage !== "undefined"
      ? props.HomePageData.newsImage
      : []
  );

  const [newsCategory] = useState(
    typeof props.HomePageData.newsCategory !== "undefined"
      ? props.HomePageData.newsCategory
      : []
  );

  const filteredOAPs = oap.filter((x) => x.StationId === Number(1));
  const filteredProgram_ = programs.filter((x) => x.StationId === Number(1));
  const filteredPrograms = programs
    .filter((x) => x.StationId === Number(1))
    .slice(0, 6);

  useEffect(() => {
    if (Object.keys(filteredPrograms).length > 0) {
      const checkProgramMatch = () => {
        const currentTime = new Date();

        const matchingProgram = programs.find((program) => {
          const startTime = new Date();
          const [startHour, startMinute] = program.StartTime.split(":");
          startTime.setHours(startHour, startMinute, 0, 0);

          const endTime = new Date();
          const [endHour, endMinute] = program.EndTime.split(":");
          endTime.setHours(endHour, endMinute, 0, 0);

          return currentTime >= startTime && currentTime <= endTime;
        });

        if (matchingProgram) {
          setCurrentProgram(matchingProgram);
        }
      };

      checkProgramMatch(); // Initial check on component mount

      const interval = setInterval(checkProgramMatch, 60000); // Check every minute

      return () => {
        clearInterval(interval);
      };
    }
    if (Object.keys(filteredPrograms).length < 1) {
      const matchingProgram = filteredPrograms.find(
        (r) => r.ProgramId === Number(1)
      );

      setCurrentProgram(matchingProgram);
    }
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      setOAP(filteredOAPs);
    }, 1000);
  }, [filteredOAPs, filteredPrograms, OAP]);

  if (Object.keys(OAP).length === 0) {
    return <HomeContentLoader />;
  }
  return (
    <ErrorBoundary>
      <div id="content">
        <div className="bg1 p93">
          <div className="container_12">
            <div className="grid_12">
              <div className="info_block"></div>
            </div>
          </div>
        </div>

        <div
          className="bg1 p93"
          style={{
            backgroundColor: "rgba(196,190,190,0.2)",
          }}
        >
          <div className="container_12">
            <div className="grid_12">
              <div className="info_block">
                <h3 className="col2">
                  Captivating moments on Enyimba FM <br />
                  <span className="col1">radio station!</span>
                </h3>
                <h4>The station that brings you intriguing programs</h4>
                <a href="https://live.enyinba.com" target="_blank">
                  <i className="fa fa-play-circle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/*PROGRAMME START*/}
        {filteredProgram_ && Object.keys(filteredProgram_).length > 0 && (
          <>
            <div className="container_12 p100">
              <h3>Programmes</h3>
              {filteredProgram_.map((item, index) => {
                return (
                  <>
                    <Link to={`/program/${item.Slug}`}>
                      <div
                        className="grid_4 wrap"
                        style={{
                          margin: "5px",
                        }}
                      >
                        <div className="thumb">
                          <img
                            src={item.Cover}
                            alt=""
                            width="300"
                            height="287.44"
                          />
                          {/*<div className="circle">*/}

                          {/*</div>*/}
                          <div
                            style={{
                              color: "black",
                              TextTransform: "lowercase",
                              fontWeight: "bold",
                              fontSize: "20px",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.ProgramName.split(" ").slice(0, 5).join(" ")}
                          </div>
                          <div
                            style={{
                              color: "black",
                              textAlign: "justify", // Center-align text
                            }}
                          >
                            {/*{item.ShortDescription.split(" ")*/}
                            {/*  .slice(0, 5)*/}
                            {/*  .join(" ")}*/}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })}
            </div>
          </>
        )}
        {/*PROGRAMME ENDS*/}

        {/*PROGRAM START*/}
        {/*<div*/}
        {/*  className="p93 bg1"*/}
        {/*  style={{*/}
        {/*    cursor: "pointer",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div className="container_12">*/}
        {/*    <div className="grid_12 center">*/}
        {/*      <h3 className="col1">Our Programmes</h3>*/}
        {/*      <div>*/}
        {/*        {filteredProgram_ && Object.keys(filteredProgram_).length ? (*/}
        {/*          <>*/}
        {/*            {filteredProgram_.map((item, index) => {*/}
        {/*              return (*/}
        {/*                <Link to={`/program/${item.Slug}`}>*/}
        {/*                  <div className="grid_4 alpha block1 mb46">*/}
        {/*                    <div*/}
        {/*                      className="card"*/}
        {/*                      style={{*/}
        {/*                        width: "300px",*/}
        {/*                        height: "280px",*/}
        {/*                        backgroundColor: "#d7d3d3",*/}
        {/*                        borderRadius: "10px",*/}
        {/*                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",*/}
        {/*                        border: "1px solid #ddd",*/}
        {/*                        marginLeft: "10px",*/}
        {/*                        color: "black",*/}
        {/*                        fontSize: "18px",*/}
        {/*                        fontStyle: "bold",*/}
        {/*                        textTransform: "none",*/}
        {/*                        margin: "10px",*/}
        {/*                        padding: "2px",*/}
        {/*                      }}*/}
        {/*                    >*/}
        {/*                      <div className="grid_4 wrap">*/}
        {/*                        <div className="thumb">*/}
        {/*                          <h3*/}
        {/*                            style={{*/}
        {/*                              marginTop: "10px",*/}
        {/*                              fontFamily: "Open Sans, sans-serif",*/}
        {/*                              fontWeight: "300",*/}
        {/*                              textTransform: "uppercase",*/}
        {/*                              display: "block",*/}
        {/*                              fontSize: "35px",*/}
        {/*                              lineHeight: "30px",*/}
        {/*                            }}*/}
        {/*                          >*/}
        {/*                            {item.ProgramName}*/}
        {/*                          </h3>*/}
        {/*                          <h4></h4>*/}
        {/*                          <p>*/}
        {/*                            {" "}*/}
        {/*                            {item.ShortDescription.split(" ")*/}
        {/*                              .slice(0, 5)*/}
        {/*                              .join(" ")}*/}
        {/*                          </p>*/}
        {/*                          <a*/}
        {/*                            href="#"*/}
        {/*                            className="link2"*/}
        {/*                            style={{*/}
        {/*                              marginTop: "10px",*/}
        {/*                            }}*/}
        {/*                          >*/}
        {/*                            Read more*/}
        {/*                          </a>*/}
        {/*                        </div>*/}
        {/*                      </div>*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </Link>*/}
        {/*              );*/}
        {/*            })}*/}
        {/*          </>*/}
        {/*        ) : (*/}
        {/*          <>*/}
        {/*            <p>There is no available program!</p>*/}
        {/*          </>*/}
        {/*        )}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*PROGRAM END*/}
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
